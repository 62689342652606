var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useState } from 'react';
/** Tracks mouse position within an DOM element */
export var useTrackMousePosition = function () {
    var _a = __read(useState({ x: 0, y: 0 }), 2), cursorPosition = _a[0], setCursorPosition = _a[1];
    var onMouseMove = useCallback(function (event) {
        var clientX = event.clientX, clientY = event.clientY, currentTarget = event.currentTarget;
        var _a = currentTarget.getBoundingClientRect(), left = _a.left, top = _a.top, width = _a.width, height = _a.height;
        var x = ((clientX - left) / width) * 100;
        var y = ((clientY - top) / height) * 100;
        setCursorPosition({ x: x, y: y });
    }, []);
    return { cursorPosition: cursorPosition, onMouseMove: onMouseMove };
};
export var useOnClickOutside = function (handler, ref) {
    useEffect(function () {
        var listener = function (event) {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return function () {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};
