export var createLazyLoadingAttribute = function (isLazy) {
    if (isLazy === void 0) { isLazy = false; }
    if (!isLazy) {
        return null;
    }
    return { loading: 'lazy' };
};
export var getActiveSlideProps = function (isActive) {
    if (!isActive) {
        return null;
    }
    return { 'data-active-slide': true };
};
var getImageUrls = function (contentImage) {
    var _a, _b, _c, _d, _e, _f;
    return ({
        x1: (_b = (_a = contentImage === null || contentImage === void 0 ? void 0 : contentImage.x1) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : '',
        x2: (_d = (_c = contentImage === null || contentImage === void 0 ? void 0 : contentImage.x2) === null || _c === void 0 ? void 0 : _c.url) !== null && _d !== void 0 ? _d : '',
        x3: (_f = (_e = contentImage === null || contentImage === void 0 ? void 0 : contentImage.x3) === null || _e === void 0 ? void 0 : _e.url) !== null && _f !== void 0 ? _f : '',
    });
};
export var transformToPrismicImage = function (image) { return ({
    mobile: getImageUrls(image.forMobile),
    tablet: getImageUrls(image.forTablet),
    desktop: getImageUrls(image.forDesktop),
    large_desktop: getImageUrls(image.forLargeDesktop),
}); };
